<template>
    <b-container fluid>
      <LayoutHeader />
        <iq-card header-class = "d-flex align-items-center justify-content-between">
                <template v-slot:headerTitle>
                    <h4 class="card-title mb-0">{{$t('select-features')}}</h4>
                </template>
                <template v-slot:headerAction>
<!--                  <button class="bg-transparent border-0" @click="selectAllFeature()">-->
<!--                    <span class="cursor-pointer">-->
<!--                      Select All-->
<!--                    </span>-->
<!--                  </button>-->
                </template>
                <template v-slot:body>
                    <b-row>
                        <b-col cols = "6" v-for="feature in features.data" :key="feature.id" class="mb-3">
                            <div class="featur p-3 h-100">
                                <div class="title  d-flex align-items-center">
                                    <b-form-checkbox :checked="featureIds.includes(feature.id)" name="check-button" @change="addFeature(null, feature.id)" />
                                    <h5 class="">{{ feature[`${$i18n.locale}_name`] }}</h5>
                                    <i :class="feature.icon"></i>
                                </div>
                                <div class="sub-feature mt-2 d-flex align-items-center flex-wrap" v-if="feature.features.length">
                                    <div class="modren-icon d-flex px-2 align-items-center" @click="addFeature(sub.id, feature.id)" :class="featureIds.includes(sub.id) ? 'modren-icon--active' : ''" v-for="sub in feature.features" :key="sub.id">
                                        <span class="modren-icon__content">
                                            {{ sub[`${$i18n.locale}_name`]}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </b-col>

                        <b-col sm="12">
                          <div class="pagination d-flex justify-content-center pt-4">
                            <b-pagination
                              dir="ltr"
                              v-model="page"
                              :total-rows="features.total"
                              :per-page="features.per_page"
                              first-number
                              last-number
                              class="mb-0 mt-1 mt-sm-0"
                              prev-class="prev-item"
                              next-class="next-item"
                            ></b-pagination>
                            </div>
                        </b-col>
                        <b-col sm="12">
                            <div class="controll py-2 d-flex justify-content-center">
                                <b-button variant="primary" @click="submit">{{$t('save')}}</b-button>
                            </div>
                        </b-col>
                  </b-row>
                </template>
        </iq-card>
    </b-container>
  </template>
<script>
import LayoutHeader from '@/components/hotelsComponents/LayoutHeader.vue'
export default {
  components: {
    LayoutHeader
  },
  data () {
    return {
      page: 1,
      featureIds: []
    }
  },
  watch: {
    page: {
      handler: function () {
        this.getFeatures(this.page)
      }
    }
  },
  methods: {
    selectAllFeature () {
      this.features.data.map((el) => {
        this.featureIds.push(el.id)
      })
    },
    submit () {
      this.addHotelFeatures({
        featureIds: this.featureIds
      })
    },
    addFeature (id, parentID) {
      if (id && this.featureIds.includes(id)) {
        this.featureIds = this.featureIds.filter(el => el !== id)
      } else if (!id && parentID && this.featureIds.includes(parentID)) {
        this.featureIds = this.featureIds.filter(el => el !== parentID)
        const childIds = this.features.data.find(f => f.id === parentID)?.features.map(f => f.id)
        this.featureIds = this.featureIds.filter(el => !childIds.includes(el))
      } else if (!id && !this.featureIds.includes(parentID)) {
        this.featureIds.push(parentID)
      } else if (id) {
        if (!this.featureIds.includes(parentID)) {
          this.featureIds.push(parentID)
        }
        this.featureIds.push(id)
      }
    }
  },
  mounted () {
    this.getFeatures()
    this.getHotel().then(() => {
      this.hotel.features.map((el) => {
        this.featureIds.push(el.id)
      })
    })
  }
}
</script>
<style lang="scss" scoped>

    .featur {
        background-color: #f9f9f9;
        border-radius: 15px;
        margin-bottom: 8px;
    }
    .featur .sub-feature {
        gap:10px
    }
    .featur .content {
        border:1px solid var(--primary);
        color:var(--primary);
        border-radius: 15px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        gap:5px;
        cursor: pointer;
    }
    .featur .content.active {
        background-color:var(--primary);
        color:#fff;
    }

.modren-icon {
    border-radius: 5px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: rgb(236, 240, 241);
    overflow: hidden;
    opacity: 0.6;
    &__icon {
      background-color: rgb(203, 209, 210);
      padding: 5px 10px;
    }
    &__content {
      margin: 0 10px
    }
    &--active {
      opacity: 1;
      border: 1px solid var(--primary)
    }
}

</style>
