import $axios from '@/plugins/axios'
const model = 'room'
export default {
  state: {
    room_images: [],
    rooms: [],
    room: {
      id: '9ae0b378-dddc-4e83-ba9a-bb54bfa6fc02',
      number: 1,
      rate: 0,
      total_reserve: 10,
      tota_views: 0,
      audit_number: 2,
      child_number: 3,
      bathroom_number: 4,
      kitchen_number: 5,
      apartment_type: 1,
      room_number: 6,
      is_room_near_pool: 1,
      smoking_policy: 1,
      hotel_id: '9ab99496-ae95-49ab-ac88-c12b932fa6db',
      floor_id: '9ac4697e-0606-4dbc-ae89-bcf0731dcbb9',
      created_by: {
        id: '9ab981f5-5213-45b8-87cf-4f6cf7b1a934',
        name: 'Admin-Hotel'
      },
      updated_by: {
        id: '9ab981f5-5213-45b8-87cf-4f6cf7b1a934',
        name: 'Admin-Hotel'
      },
      created_at: '2023-12-18 11:25:43 am',
      updated_at: '2023-12-18 11:25:43 am'
    }
  },
  getters: {
    rooms: state => state.rooms,
    room: state => state.room,
    room_images: state => state.room_images
  },
  actions: {
    async getRooms ({ commit }, filterData = { take: 10, skip: 0, name: '' }) {
      const response = await $axios.get(`/${model}?include=createdBy,updatedBy,images,floor`)
      commit('SET_ROOMS', response.data)
    },
    async getRoom ({ commit }, id) {
      const response = await $axios.get(`/${model}/${id}?include=images,services`)
      commit('SET_ROOM', response.data.data)
    },
    async addRooms ({ commit }, payload) {
      await $axios.post(`/${model}`, payload)
    },
    async updateRooms ({ commit }, payload) {
      const response = await $axios.post(`/${model}/${payload.id}`, payload.data)
      commit('UPDATE_ROOMS', response.data.data)
    },

    async removeRooms ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_ROOMS', response.data.data.id)
    },
    async getRoomImage ({ commit }, id) {
      const response = await $axios.get(`/roomImage?filter[room_id]=${id}&take=30&include=image`)
      commit('SET_ROOM_IMAGE', response.data.data)
    },
    async setRoomImage ({ commit }, payload) {
      await $axios.post('/roomImage', payload)
    },
    async toggleShowRome ({ commit }, payload) {
      await $axios.put('/show-or-display-room-per-days', payload)
    },
    async removeRoomImage ({ commit }, id) {
      await $axios.delete(`/roomImage/${id}`)
    },
    async ChangeAvailabelRoom (_, id) {
      return await $axios.put(`/available-or-not/room/${id}`)
    }
  },
  mutations: {
    SET_ROOMS (state, data) {
      state.rooms = data
    },
    SET_ROOM (state, data) {
      state.room = data
    },
    SET_ROOM_IMAGE (state, data) {
      state.room_images = data
    },
    SORT_ROOMS (state, data) {
      state.rooms.data.push(data)
    },
    UPDATE_ROOMS (state, data) {
      state.rooms.data = state.rooms.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_ROOMS (state, id) {
      state.rooms.data = state.rooms.data.filter(x => x.id !== id)
    }
  }
}
