<template>
  <div id="rooms-info">
    <b-container fluid>
      <b-modal id="show-room-modal" :ok-title="$t('save')" :cancel-title="$t('cancel')" :title="$t('room-status')"
               size="sm" hide-header-close @ok="handelShowRoom">
        <b-form class="gap-5">
          <b-row>
            <b-col sm="12">
              <b-form-select v-model="showRoomPayload.type" :options="roomsTypes"></b-form-select>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-modal id="room-services" :ok-title="$t('save')" :cancel-title="$t('cancel')" :title="$t('room-services')" size="xl" @ok="udateRoomServicese">
        <b-form class="gap-5">
          <div class="featur p-3 h-100">
              <div class="mt-2 d-flex align-items-center flex-wrap" style="gap: 5px">
                  <div class="modren-icon d-flex px-2 align-items-center" :class="serviceIds.includes(service.id) ? 'modren-icon--active' : ''" v-for="(service,index) in services.data" :key="index" @click="toggleService(service.id)">
                      <span class="modren-icon__content">
                          <i v-if="serviceIds.includes(service.id)" class="ri-check-line"></i>
                          {{ service[`${$i18n.locale}_name`] }}
                      </span>
                  </div>
              </div>
          </div>
        </b-form>
      </b-modal>
      <div class="add-image-section">
        <b-modal id="add-img-modal" :ok-title="$t('save')" :cancel-title="$t('cancel')" :title="$t('upload-image')"
                 size="xl" @ok="uploadRoomImage">
          <b-form class="gap-5">
            <b-row>
              <b-col sm="12" md="6" lg="3" v-for="(image,index) in hotelImgs" :key="index">
                <div class="position-relative">
                  <input v-model="imagePayload.images" :value="image.id" type="checkbox" class="position-absolute"
                         style="top: 10px; left: 10px; width: 18px; height: 18px;">
                  <img class="img-fluid mb-1 rounded border" :src="image.image_path" alt="">
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </div>
      <div class="remove-image-section">
        <b-modal id="remove-img-modal" :ok-title="$t('save')" :cancel-title="$t('cancel')" :title="$t('remove-image-model')"
                 size="l" @ok="removeImage">
          <div>
            <h3>{{ $t('confirm-remove-image') }}</h3>
            <p>{{ $t('image-will-be-deleted') }}</p>
          </div>
        </b-modal>
      </div>
      <div class="room-statistic pb-3 mb-3 border-bottom">
        <b-row>
          <b-col md="6" class="mb-0" lg="3" v-for="(stats,index) in roomState" :key="index">
            <iq-card class="mb-2">
              <template v-slot:body>
                <div class="d-flex rounded align-items-center mb-3 mb-lg-0">
                  <div class="rounded-circle iq-card-icon iq-bg-primary  dark-icon-light mr-3"><i
                    :class="stats.icon"></i></div>
                  <div class="text-left">
                    <h6>{{ $t(stats.title) }}</h6>
                    <p class="mb-0">{{ room[stats.key] }}</p>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col md="6" class="mb-3" lg="3">
            <iq-card>
              <template v-slot:body>
                <div class="d-flex rounded align-items-center mb-3 mb-lg-0">
                  <div class="rounded-circle iq-card-icon iq-bg-primary  dark-icon-light mr-3"><i
                    class="fas fa-eye"></i></div>
                  <div class="text-left">
                    <h6>{{ $t('room-status') }}</h6>
                    <p class="mb-0 text-success" v-if="room.is_available">{{ $t('available') }}</p>
                    <p class="mb-0 text-danger" v-else> {{ $t('unavailable') }} </p>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col sm="12" lg="8">
          <div class="sub-rooms">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('nav.rooms') }}</h4>
              </template>
              <template v-slot:headerAction>
                <b-button :variant="room.is_available?'danger':'success'" class="" @click="changeRoomStatus()">
                  <span v-if="room.is_available">{{ $t('switch-to-unavailable') }}</span>
                  <span v-else>{{ $t('switch-to-available') }}</span>
                </b-button>
                <b-button variant="primary" class="mx-2" @click="$router.push(`/add-rooms/${$route.params.id}`)">
                  {{ $t('edit-room-info') }}
                </b-button>
                <b-button variant="success" class="mx-2" @click="$bvModal.show('room-services')">{{
                    $t('room-services')
                  }}
                </b-button>
                <b-button variant="info" @click="$router.push(`/rooms-price/${$route.params.id}`)">{{
                    $t('room-price')
                  }}
                </b-button>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col sm="6" md="3" class="mb-2">
                    <div @click="showAddModale" style="min-height: 200px; height: 100%;"
                         class="add-room-image border border-dashed border-2 rounded d-flex align-items-center justify-content-center flex-column">
                      <i class="ri-add-line"></i>
                      <span>
                              {{ $t('upload-image') }}
                            </span>
                    </div>
                  </b-col>
                  <b-col sm="6" md="3" class="mb-2" v-for="(image,index) in roomImagesList" :key="index">
                    <div>
                      <b-button variant="danger" @click="showDeleteImageModale(image.id)" class="delete-image" ><i class="ri-delete-bin-line p-0"></i></b-button>
                      <img class="img-fluid mb-1 rounded border glightbox" style="height: 200px;width: 100%;object-fit: cover;" :src="image.image.image_path" alt="">
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="room.images && room.images.length < 5">
                  <b-col cols="12" class="text-center">
                    {{ $t('room-image-hint') }}
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </div>
        </b-col>
        <b-col sm="12" md="4">
          <iq-card>
            <template v-slot:headerTitle>
              <!--                    <h4 class="card-title">active calender</h4>-->
            </template>
            <template v-slot:body>
              <FullCalendar ref="calendar" :options='calendarOptions'>
                <template v-slot:eventContent='arg'>
                  <div class="event-container text-center d-flex align-items-center justify-content-center">
                    <span class="avaliable" v-if="arg.event.extendedProps.avaliable"></span>
                    <span class="unavaliable" v-else></span>
                  </div>
                </template>
              </FullCalendar>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import interactionPlugin from '@fullcalendar/interaction'// for selectable
import dayGridPlugin from '@fullcalendar/daygrid'
import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.css'

export default {
  data () {
    return {
      room_image_data: [],
      serviceIds: [],
      roomsTypes: [
        {
          text: this.$t('show'),
          value: 1
        },
        {
          text: this.$t('hide'),
          value: 0
        }
      ],
      imagePayload: {
        images: []
      },
      removeImageId: {},
      showRoomPayload: {
        room_id: this.$route.params.id,
        type: 1,
        days: []
      },
      roomImagesList: [],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        selectable: true,
        select: this.handelSelect,
        initialView: 'dayGridMonth',
        weekends: true,
        // eventClick: this.editInit,
        datesSet: this.nextBtnEvent,
        events: [],
        eventColor: '#f8f7fd',
        showNonCurrentDates: false,
        validRange: {
          start: new Date() // Start from today's date
        }
      },
      roomState: [
        {
          title: 'room-name',
          icon: 'fas fa-sort-amount-down',
          key: 'name'
        },
        {
          title: 'views',
          icon: 'fas fa-eye',
          key: 'total_views'
        },
        {
          title: 'adult-number',
          icon: 'fas fa-male',
          key: 'adult_number'
        },
        {
          title: 'child-number',
          icon: 'fas fa-child',
          key: 'child_number'
        },
        {
          title: 'total-reverse',
          icon: 'fas fa-ticket-alt',
          key: 'total_reserve'
        },
        {
          title: 'bathroom-Number',
          icon: 'fas fa-sink',
          key: 'bathroom_number'
        },
        {
          title: 'kitchen-Number',
          icon: 'fas fa-cocktail',
          key: 'kitchen_number'
        }
      ]
    }
  },
  components: {
    FullCalendar
  },
  methods: {
    toggleService (serviceId) {
      if (this.serviceIds.includes(serviceId)) {
        this.serviceIds = this.serviceIds.filter(s => s !== serviceId)
      } else {
        this.serviceIds.push(serviceId)
      }
    },
    removeImage () {
      this.removeRoomImage(this.removeImageId).then(() => {
        this.getRoomImage(this.$route.params.id).then(() => {
          this.roomImagesList = this.room_images
          this.$nextTick(() => {
            GLightbox({
              selector: '.glightbox'
            }) // init lightbox
          })
        })
      })
    },
    getDatesBetween (startDate, endDate) {
      const start = new Date(startDate)
      const end = new Date(endDate)
      const dates = []

      // Check if end date is before start date
      if (end < start) {
        return []
      }

      // Loop through dates
      const current = new Date(start)
      // eslint-disable-next-line no-unmodified-loop-condition
      while (current <= end) {
        dates.push(this.formatDate(current))
        current.setDate(current.getDate() + 1) // Move to the next day
      }
      this.showRoomPayload.days = dates
    },
    formatDate (date) {
      // Format date to YYYY-MM-DD (you can adjust this format)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    showAddModale () {
      this.$bvModal.show('add-img-modal')
    },
    showDeleteImageModale (id) {
      this.removeImageId = id
      this.$bvModal.show('remove-img-modal')
    },
    handelSelect (selectInfo) {
      this.getDatesBetween(selectInfo.startStr, selectInfo.endStr)
      this.$bvModal.show('show-room-modal')
    },
    udateRoomServicese () {
      this.addRoomServices({
        id: this.$route.params.id,
        servicesIds: {
          serviceIds: this.serviceIds
        }
      }).then(() => {
        this.getRoom(this.$route.params.id)
      })
    },
    uploadRoomImage () {
      var data = {}
      this.imagePayload.images.forEach((el, index) => {
        data[`images[${index}]`] = el
      })
      data.room_id = this.$route.params.id
      this.setRoomImage(this.returnData(data)).then(() => {
        this.getRoomImage(this.$route.params.id).then(() => {
          this.roomImagesList = this.room_images
          this.$nextTick(() => {
            GLightbox({
              selector: '.glightbox'
            }) // init lightbox
          })
        })
        this.getRoom(this.$route.params.id)
      }).then(() => {
        this.getRoomImage(this.$route.params.id).then(() => {
          this.roomImagesList = this.room_images
          this.$nextTick(() => {
            GLightbox({
              selector: '.glightbox'
            }) // init lightbox
          })
        })
      })
    },
    changeRoomStatus () {
      this.confermMsg(() => {
        this.ChangeAvailabelRoom(this.$route.params.id).then(() => {
          this.getRoom(this.$route.params.id).then(() => {
            this.room.services.map(el => {
              this.serviceIds.push(el.id)
            })
          })
        })
      })
    },
    getPriceData (query) {
      this.getRoomPriceList({
        id: this.$route.params.id,
        query
      }).then(() => {
        var eventData = []
        this.roomPrice.data.map(el => {
          eventData.push({
            start: el.date,
            avaliable: el.is_available
          })
        })
        this.calendarOptions.events = eventData
      })
    },
    handelShowRoom () {
      this.toggleShowRome({
        room_id: this.showRoomPayload.room_id,
        days: this.showRoomPayload.days.slice(0, this.showRoomPayload.days.length - 1),
        type: this.showRoomPayload.type
      }).then(() => {
        this.getPriceData(this.selectedDateInfo)
      })
    },
    nextBtnEvent (info) {
      this.selectedDateInfo = {
        from: info.startStr.slice(0, 10),
        to: info.endStr.slice(0, 10)
      }
      this.getPriceData(
        {
          from: info.startStr.slice(0, 10),
          to: info.endStr.slice(0, 10)
        }
      )
    }
  },
  mounted () {
    this.getRoom(this.$route.params.id).then(() => {
      this.room.services.map(el => {
        this.serviceIds.push(el.id)
      })
    })
    this.getRooms()
    this.getRoomImage(this.$route.params.id).then(() => {
      this.roomImagesList = this.room_images
      this.$nextTick(() => {
        GLightbox({
          selector: '.glightbox'
        }) // init lightbox
      })
    })
    this.getHotel()
    this.getHotelImg()
    this.getServicesRoom()
  }

}
</script>
<style lang="scss">

.rooms-card {
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.rooms-card img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.rooms-card .info {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  border-radius: 5px;

}

.event-container span {
  padding: 5px 10px;
  border-radius: 10px;
  display: block;
}

.event-container .avaliable {
  background-color: #66ff66;
}

.event-container .unavaliable {
  background-color: #ff4b4b;
}
.delete-image {
  position: absolute;
  top: 5px;
  right: 20px;
  z-index: 1;
}
.modren-icon {
    border-radius: 5px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: rgb(236, 240, 241);
    overflow: hidden;
    &__icon {
      background-color: rgb(203, 209, 210);
      padding: 5px 10px;
    }
    &__content {
      margin: 0 10px
    }
    &--active {
      opacity: 1;
      background-color: var(--iq-primary);
      color: #fff;
    }
}
</style>
